import React from 'react';
import { Grid, Button, Paper } from '@material-ui/core';
import * as API from '../api';
import { Alert } from '@material-ui/lab';
import QRCode from "qrcode.react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { Trans } from '@lingui/macro';
import parsePhoneNumber from 'libphonenumber-js';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import ReactCodeInput from 'react-code-input';

export default function AuthorizeDesktopView(props: any) {
    const loginParams = props.loginParams as API.LoginResponse;
    const usePhoneNumber = false;
    const [phone, setPhone] = React.useState("");
    let [code, setCode] = React.useState("");
    const [user, setUser] = React.useState("");
//    const [infoMessage, setInfoMessage] = React.useState(<Alert severity="info"><Trans>Enter your phone number</Trans></Alert>);
    const [infoMessage, setInfoMessage] = React.useState(<Alert severity="info"><Trans>Enter your LogPass code</Trans></Alert>);
    const [connectedToWebsocket, setConnectedToWebsocket] = React.useState(false);
    const [waitingForSubmit, setWaitingForSubmit] = React.useState(false);
    const [redirect, setRedirect] = React.useState<JSX.Element | null>(null);

    let onClickLearnMore = (event : any) => {
        var win = window.open('https://logpass.me/', '_blank');
        if(win)
          win.focus();
      };
    
    let onCodeChange = (newCode : string) => {
      const autoSubmit = code != newCode && newCode.length == 6;
      setCode(newCode);
      code = newCode;
      if(autoSubmit)
        submitCode(null);
    };
  
    let submitPhoneNumber = (event: any) => {
        if(event)
          event.preventDefault();
        if(waitingForSubmit || !loginParams)
          return;
        if(!connectedToWebsocket) {
          setInfoMessage(<Alert severity="error"><Trans>Can't connect to WebSocket backend.</Trans></Alert>);  
          return;
        }
        setInfoMessage(<Alert severity="info"><Trans>Sending message to your phone...</Trans></Alert>);  
        setWaitingForSubmit(true);

        // experimental
        API.notify(user, loginParams.links.review.replace("https://app.logpass.me/", "logpass://"));

        API.assign(loginParams.id, {
          user: user,
          send_push_notification: true
        }).then(() => {
          setWaitingForSubmit(false);
          setInfoMessage(<Alert severity="success"><Trans>Message has been sent. Check your mobile phone.</Trans></Alert>);
        }).catch((e) => {
          setWaitingForSubmit(false);
          setInfoMessage(<Alert severity="error"><Trans>Error: {e.message}</Trans></Alert>);  
          console.log(e, e.errors);
        })
    };

    let submitCode = async (event: any) => {
      if(event)
        event.preventDefault();
      if(waitingForSubmit || !loginParams)
        return;
      if(!connectedToWebsocket) {
        setInfoMessage(<Alert severity="error"><Trans>Can't connect to WebSocket backend.</Trans></Alert>);  
        return;
      }

      /*
      if(user.length > 0) {
        setInfoMessage(<Alert severity="info"><Trans>Sending notification...</Trans></Alert>);  
        setWaitingForSubmit(true);    
        API.assign(loginParams.id, {
            user: user,
            send_push_notification: true
        }).then(() => {
          setWaitingForSubmit(false);
          setInfoMessage(<Alert severity="success"><Trans>Message has been sent. Check your mobile phone.</Trans></Alert>);  
        }).catch((e) => {
          setWaitingForSubmit(false);
          setInfoMessage(<Alert severity="error"><Trans>Error: {e.message}</Trans></Alert>);  
          console.log(e, e.errors);
        })
        return;
      }
      */

      if (code.length != 6) {
        setInfoMessage(<Alert severity="error"><Trans>Invalid code.</Trans></Alert>);
        return;
      }
      setInfoMessage(<Alert severity="info"><Trans>Checking code...</Trans></Alert>);  
      setWaitingForSubmit(true);    
      API.getCode(code).then(user => {
        setUser(user.user);
        setPhone(user.phone_number);
        localStorage.setItem("phoneNumber", user.phone_number); 
        localStorage.setItem("user", user.user);   

        // experimental
        API.notify(user.user, loginParams.links.review.replace("https://app.logpass.me/", "logpass://"));

        return API.assign(loginParams.id, {
          user: user.user,
          send_push_notification: true
        })
      }).then(() => {
        setWaitingForSubmit(false);
        setInfoMessage(<Alert severity="success"><Trans>Message has been sent. Check your mobile phone.</Trans></Alert>);  
      }).catch((e) => {
        setWaitingForSubmit(false);
        setInfoMessage(<Alert severity="error"><Trans>Error: {e.message}</Trans></Alert>);  
        console.log(e, e.errors);
      })
    };
    
    React.useEffect(() => {
        let phoneNumber = localStorage.getItem("phoneNumber") || "";
        setPhone(phoneNumber);
        setUser(localStorage.getItem("user") || "");

        if(phoneNumber.length > 0) {
          setInfoMessage(<Alert severity="info"><Trans>You can use your previously used device.</Trans></Alert>)          
        }
        
        try {
          let ws = new WebSocket(loginParams.links.websocket.replace("http", "ws"));
          let retryEvent : NodeJS.Timeout | null = null;
    
          const configureWebsocket = (websocket : WebSocket) => {
            websocket.onopen = (event) => {
              console.log("websocket connected");
              setConnectedToWebsocket(true);
            }
            websocket.onmessage = (event) => {
              try {
                const message = JSON.parse(event.data);
                const data = message.data;
                const redirect_url = data.redirect_uri;
                setRedirect(<Redirect to={{
                  pathname: "/redirect",
                  state: { 
                      to: loginParams.client.name,
                      url: redirect_url
                  }
                }}></Redirect>);
                ws.close();
              } catch(e) {
                setRedirect(<Redirect push={true} to={{
                  pathname: "/error",
                  state: { 
                      error: new e,
                  }
                }}></Redirect>);
              }
            }
            websocket.onclose = (event) => {
              console.log("websocket disconnected");
              setConnectedToWebsocket(false);
              if(!retryEvent) {
                retryEvent = setTimeout(() => {
                  retryEvent = null;
                  ws = new WebSocket(loginParams.links.websocket.replace("http", "ws"));
                  configureWebsocket(ws);
                }, 50);
              }
            }
            websocket.onerror = (event) => {
              console.error("websocker error", event);
              setConnectedToWebsocket(false);
              setRedirect(<Redirect push={true} to={{
                pathname: "/error",
                state: { 
                    error: new Error("Websocket connection error"),
                }
              }}></Redirect>);
            }  
          }
    
          configureWebsocket(ws);
    
          return () => {
            if(retryEvent) {
              clearTimeout(retryEvent);
              retryEvent = null;
            }
            ws.onclose = null;
            ws.close();
          };
        } catch(e) {
          setInfoMessage(<Alert severity="error"><Trans>Can't connect to WebSocket backend.</Trans></Alert>);  
          setConnectedToWebsocket(false);
        }
    }, [loginParams]);

  const renderForm = () => {
    if(user.length > 0 && phone.length > 0) {
      return <>
        <Grid item xs={12} style={{width: '100%'}}>
          {infoMessage}          
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} className="MuiAlert-standard" style={{padding: "5px 20px 5px 20px", marginBottom: "10px", marginTop: "10px"}}>
            <Grid container direction="row" justify="space-between" alignItems="center" spacing={1}>
              <Grid item xs={7}>
                <div style={{marginRight: "10px", textAlign: "center", fontWeight: "bold"}}>
                  <Trans>Notify {phone}</Trans>
                </div>
              </Grid>
              <Grid item xs={5}>
                <Button variant="contained" fullWidth color="primary" onClick={() => {
                  setPhone("");
                  setUser("");
                }}><Trans>Use code</Trans></Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} style={{width: '100%'}}>
          <Button type="submit" variant="contained" fullWidth color="primary" onClick={submitPhoneNumber}><Trans>Continue</Trans></Button>
        </Grid>        
      </>;

    }
    return <form onSubmit={phone.length > 0 ? submitPhoneNumber : submitCode}>
      <Grid container direction="column" alignItems="center" spacing={1}>
        <Grid item xs={12} style={{width: '100%'}}>
          {infoMessage}          
        </Grid>
        <Grid item xs={12}>
          <ReactCodeInput fields={6} type='number' inputMode='numeric' name='code' onChange={onCodeChange} />
        </Grid>
        <Grid item xs={12} style={{width: '100%'}}>
          <Button type="submit" variant="contained" fullWidth color="primary"><Trans>Continue</Trans></Button>
        </Grid>
      </Grid>
    </form>;
  }

  return (
    <>
      {redirect}
      <Grid container direction="column" alignItems="stretch">
        <Grid item xs={12}>
          <h1 style={{textAlign: 'center'}}>
            <Trans>Login on</Trans> { loginParams.client.name }
          </h1> 
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} className="MuiAlert-standardInfo" style={{padding: "20px", marginBottom: "10px"}}>
            <Grid container direction="row" justify="space-between" alignItems="center" spacing={1}>
              <Grid item xs={9}>
                <div style={{marginRight: "10px"}}>
                  <Trans>To use LogPass you need to have our mobile app. Open LogPass app and enter your code or scan QR to continue.</Trans>
                </div>
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained" fullWidth color="primary" onClick={onClickLearnMore}><Trans>Learn more</Trans></Button>
              </Grid>
            </Grid>
          </Paper>          
        </Grid>
      </Grid>
      <Grid container direction="row" justify="space-between" alignItems="center" spacing={1}>
        <Grid item xs={8}>
          { renderForm() }
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
              <Grid item>
                <QRCode value={loginParams.links.review} level="M" size={170} renderAs="canvas" style={{marginTop: "5px"}}></QRCode>
              </Grid>
            </Grid>
        </Grid>
      </Grid>
    </>
  );
}
