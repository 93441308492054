import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { Link, Redirect } from "react-router-dom";
import * as API from '../api';
import { Trans } from '@lingui/macro';

export default function LoadingView(props: any) {
    const title = props.title || <Trans>Loading...</Trans>
    const timeout = props.timeout || 5000;
    const [redirect, setRedirect] = React.useState<JSX.Element | null>(null);

    React.useEffect(() => {
        if(props.redirect) {
            setRedirect(props.redirect);
            return;
        }
        const timeoutEvent = setTimeout(() => {
            setRedirect(<Redirect push={true} to={{
                pathname: "/error",
                state: { 
                    error: new Error("Timeout"),
                }
              }}></Redirect>);    
        }, timeout);
        return () => {
            clearTimeout(timeoutEvent);
        }
    }, [props]);

    return <>
        <Grid container direction="column" justify="space-between" alignItems="center" spacing={2} style={{height: "200px"}}>
            <Grid item>
                <h1 style={{textAlign: 'center', margin: 0}}>{title}</h1>          
            </Grid>
            <Grid item>
                <CircularProgress size="100px" />
            </Grid>
            <Grid item>
            </Grid>
        </Grid>
        {redirect}
    </>;
}
