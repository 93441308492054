import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Trans } from '@lingui/macro';

export default function IndexView(props: any) {
    return <Grid container direction="column" justify="space-between" alignItems="center" spacing={2} style={{height: "200px"}}>
        <Grid item>
            <h1 style={{textAlign: 'center', margin: 0}}><Trans>Index</Trans></h1>          
        </Grid>
        <Grid item>
            Main page of app.logpass.me
        </Grid>
        <Grid item>
        </Grid>
    </Grid>;
}
