const API_URL = "https://api.logpass.dev"
let API_LANGUAGE = "pl-PL,pl;q=0.9,en-US;q=0.8,en;q=0.7";

export function setLanguage(language : string) {
    API_LANGUAGE = language;
}

export interface ApiSubError
{
    code: string;
    message: string;
    pointer?: string;
    extra_data?: any;
}

export class ApiError extends Error {
    code : number;
    code_str : string;
    message : string;
    errors: ApiSubError[];

    constructor(code : number, code_str : string, message : string, errors : ApiSubError[] = []) {
        super(message);
        this.code = code;
        this.code_str = code_str;
        this.message = message;
        this.errors = errors;
    }
}

async function query(method : "GET" | "POST" | "PATCH" | "DELETE", path : string, data : any = null) : Promise<any>
{
    if(!path.startsWith("/"))
        throw Error("API path should start with /");
    if(method !== "GET" && !data)
        throw Error("Missing body data");

    const headers = {
        'Accept-Language': API_LANGUAGE,
        ...(data && {
           'Content-Type': 'application/json'
        })        
    };
    try {
        let query = await fetch(API_URL + path, {
            method: method,
            cache: 'no-cache',
            headers: headers,
            ...(data && {
                body: JSON.stringify(data)
            })    
        });
        if(query.status == 204) {
            return {};
        }
        let json : any = null;
        try {
            json = await query.json();
        } catch(e) {}
        if(query.status >= 400 && query.status < 500) {
            if(!json)
                throw new ApiError(query.status, query.status.toString(), query.status.toString());
            throw new ApiError(query.status, json["code"], json["message"], json["errors"]);
        }
        if(!json)
            return {};
        if(json["_links"])
            json["data"]["links"] = json["_links"];
        return json["data"];
    } catch(e) {
        if(e instanceof ApiError)
            throw e;
        throw new ApiError(-1, "other", e.message);
    }
}

export interface LoginRequest {
    client_id : string;
    redirect_uri : string;
    response_type : string;
    scope : string;
    is_remote : boolean;
    code_challenge? : string;
    code_challenge_method? : string;
    state? : string;
    login_hint? : string;
    nonce? : string;
}

export interface LoginResponseClientScopes {
    scope : string;
    description : string;
}

export interface LoginResponseClient {
    client_id : string;
    name : string;
    website_url : string;
    sctops : LoginResponseClientScopes[];
}

export interface LoginResponseLinks {
    review : string;
    websocket : string;
}

export interface LoginResponse {
    id : string;
    client : LoginResponseClient;
    links : LoginResponseLinks;
    _links : LoginResponseLinks;
}

export async function initLogin(params : LoginRequest) : Promise<LoginResponse>
{
    return query("POST", "/auth/o/authorization-attempts/", params);
}

export async function getLogin(attemptId : string) : Promise<LoginResponse>
{
    return query("GET", `/auth/o/authorization-attempts/${attemptId}/`);
}

export interface AssignRequest {
    user : string;
    send_push_notification : boolean;
}

export async function assign(attemptId : string, params : AssignRequest) : Promise<void>
{
    return query("POST", `/auth/o/authorization-attempts/${attemptId}/user/`, params);
}

export async function abort(attemptId : string) : Promise<void>
{
    return query("DELETE", `/auth/o/authorization-attempts/${attemptId}/`);
}

export interface CodeResponse {
    user : string;
    phone_number : string;
}

export async function getCode(code : string) : Promise<CodeResponse>
{
    return query("GET", `/users/codes/${code}/`);
}

export async function notify(userId : string, deepLink : string) : Promise<Boolean>
{
    let query = await fetch("https://ws.logpass.dev/notify/" + userId, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'link': deepLink
        })
    });
    return query.status == 200;
}
