import React from 'react';
import './App.css';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import 'react-phone-input-2/lib/material.css';
import { isMobile } from "react-device-detect";
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { BrowserRouter as Router, Route, Switch, Link, Redirect, useHistory } from "react-router-dom";
import * as API from './api';
import catalogEn from './locales/en.js';
import catalogPl from './locales/pl.js';
import ErrorView from './views/error';
import IndexView from './views/index';
import AuthorizeView from './views/authorize';
import PrepareAuthorizeView from './views/prepare_authorize';
import RedirectView from './views/redirect';

i18n.load('en', catalogEn.messages);
i18n.load('pl', catalogPl.messages);
let language = localStorage.getItem("language");
if(!language) {
  if(navigator.language.indexOf("pl") !== -1) {
    language = "pl";
  } else {
    language = "en";
  }
}
i18n.activate(language);

function App() {
  const [language, setLanguage] = React.useState(i18n.locale);

  const onLanguageChange = (event : any) => {
    let language = event.target.value as string;
    setLanguage(language);
    i18n.activate(language);
    localStorage.setItem("language", language);
  };

  return (
    <I18nProvider i18n={i18n}>
      <div className="App">
        <header className={isMobile ? "mobileApp" : "desktopApp"}>
          <div className="languageSelector">
            <FormControl variant="outlined" size="small" fullWidth>
              <Select id="language" value={language} inputProps={{MenuProps: {disableScrollLock: true}}} onChange={onLanguageChange}>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="pl">Polski</MenuItem>
              </Select>
            </FormControl>
          </div>
        </header>
        <div className="fullSeparator"></div>
        <main className={isMobile ? "mobileApp" : "desktopApp"}>
          <Router>
            <Switch>
              <Route path="/" exact component={IndexView} />
              <Route path="/authorize" exact component={PrepareAuthorizeView} />
              <Route path="/authorize/:id" exact component={AuthorizeView} />
              <Route path="/error" exact component={ErrorView} />
              <Route path="/redirect" exact component={RedirectView} />
              <Route component={ErrorView} />
            </Switch>
          </Router>
        </main>
        <div className="fullSeparator"></div>
        <footer className={isMobile ? "mobileApp" : "desktopApp"}>
          app.logpass.me
        </footer>
      </div>
      </I18nProvider>
  );
}

export default App;
