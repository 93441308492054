import React from 'react';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { Link, Redirect } from "react-router-dom";
import * as API from '../api';
import { Trans } from '@lingui/macro';
import Alert from '@material-ui/lab/Alert';

export default function RedirectView(props: any) {
    const state = props.location.state; 
    const to = state && state.to || null;
    const url = state && state.url as string;

    const redirect = () => {
        if(!url)
            return;
        window.location.href = url;
    };

    React.useEffect(() => {
        redirect();
    }, [url]);

    if(!url) {
        return <Redirect push={true} to={{
            pathname: "/error",
            state: { 
                error: new Error("Missing redirect url"),
            }
          }}></Redirect>;
    }

    return <>
        <Grid container direction="column" justify="space-between" alignItems="center" spacing={2} style={{height: "200px"}}>
            <Grid item>
                <h1 style={{textAlign: 'center', margin: 0}}>{to ? <><Trans>Redirecting to</Trans> {to}</> : <Trans>Redirecting...</Trans>}</h1>          
            </Grid>
            <Grid item>
                <Alert severity="info"><Trans>Click on bellow button to continue</Trans></Alert>
            </Grid>
            <Grid item>
                <Button variant="contained" fullWidth color="primary" onClick={redirect} style={{width: "200px"}}><Trans>Continue</Trans></Button>
            </Grid>
        </Grid>
    </>;
}
