import React from 'react';
import { Grid } from '@material-ui/core';
import { Link, Redirect, useHistory } from "react-router-dom";
import * as API from '../api';
import LoadingView from './loading';
import { isMobile } from "react-device-detect";
import AuthorizeDesktopView from './authorize_desktop';
import AuthorizeMobileView from './authorize_mobile';

export default function LoginView(props: any) {
    const id = props.match.params.id as string;
    const state : any = props.location.state;
    const loginParams = ((state && state.loginParams) || null) as API.LoginResponse | null;
    const history = useHistory();

    const [redirect, setRedirect] = React.useState<JSX.Element | null>(null);

    React.useEffect(() => {
        if(loginParams)
            return;
        (async () => {
            try {
                history.replace(history.location.pathname, {
                    loginParams: await API.getLogin(id)
                });
            } catch(e) {
                setRedirect(<Redirect to={{
                    pathname: "/error",
                    state: { 
                        error: e,
                    }
                  }}></Redirect>);    
            }
        })();
    }, [loginParams]);

    if(!loginParams)
        return <LoadingView title={"Login"} redirect={redirect} />;

    if(isMobile)
        return <AuthorizeMobileView loginParams={loginParams} />;

    return <AuthorizeDesktopView loginParams={loginParams} />;
}
