import React from 'react';
import { Grid } from '@material-ui/core';
import { Link, Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";
import URLParse from "url-parse";
import * as API from '../api';
import LoadingView from './loading';
import { Trans } from '@lingui/macro';

export default function PrepareLoginView(props: any) {
    const [redirect, setRedirect] = React.useState<JSX.Element | null>(null);

    React.useEffect(() => {
        (async () => {
          try {
            const url = URLParse(window.location.href, true);
            const query = url.query;
            let loginParams : API.LoginRequest = {
              client_id: query.client_id as string,
              redirect_uri: query.redirect_uri as string,
              response_type: query.response_type as string,
              scope: query.scope as string,
              is_remote: !isMobile
            };
            if(query.code_challenge)
              loginParams.code_challenge = query.code_challenge as string;
            if(query.code_challenge_method)
              loginParams.code_challenge_method = query.code_challenge_method as string;
            if(query.state)
              loginParams.state = query.state as string;
            if(query.login_hint)
              loginParams.login_hint = query.login_hint as string;
            if(query.nonce)
              loginParams.nonce = query.nonce as string;
            else if(loginParams.redirect_uri.toLowerCase().indexOf("ondemand") !== -1)
              loginParams.nonce = loginParams.state;

            const loginResponse = await API.initLogin(loginParams);
            setRedirect(<Redirect to={{
                pathname: `/authorize/${loginResponse.id}`,
                state: {
                    loginParams: loginResponse
                }
            }}></Redirect>);
          } catch(e) {
            setRedirect(<Redirect to={{
                pathname: "/error",
                state: { 
                    error: new Error(e)
                }
              }}></Redirect>);
          }
        })();
      }, []);
      
    return <LoadingView 
        title={<Trans>Loading...</Trans>}
        redirect={redirect} 
    />;
}
