import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import * as API from '../api';
import { Alert } from '@material-ui/lab';
import { isIOS } from 'react-device-detect';

export default function AuthorizeMobileView(props: any) {
    const loginParams = props.loginParams as API.LoginResponse;
    const [infoMessage, setInfoMessage] = React.useState(<Alert severity="info"><Trans>Open LogPass application to continue.</Trans></Alert>);
    
    let onClickOpen = (event : any) => {    
      setInfoMessage(<Alert severity="warning"><Trans>If LogPass application didn't open you need to click Get LogPass App button.</Trans></Alert>);
      window.location.href = `logpass://authorize/${loginParams.id}`;
    };
  
    let onClickLearnMore = (event : any) => {
      var win = window.open('https://logpass.me/', '_blank');
      if(win)
        win.focus();
    };
  
    return (
      <>
        <Grid container direction="column" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <h2 style={{textAlign: 'center', margin: "0px"}}>
                <Trans>Login on</Trans> { loginParams.client.name }
            </h2>          
          </Grid>
          <Grid item xs={12}>
            {infoMessage}
          </Grid>
          <Grid item xs={12}>
            <Button size="large" variant="contained" fullWidth color="primary" onClick={onClickOpen}><Trans>Open LogPass App</Trans></Button>
          </Grid>
          <Grid item xs={12}>
            { isIOS ?
              <Button size="large" variant="contained" fullWidth color="primary" onClick={onClickLearnMore}><Trans>Get LogPass App from App Store</Trans></Button> :
              <Button size="large" variant="contained" fullWidth color="primary" onClick={onClickLearnMore}><Trans>Get LogPass App from Google Play</Trans></Button>
            }
          </Grid>
          <Grid item xs={12}>
            <Button size="large" variant="contained" fullWidth color="primary" onClick={onClickLearnMore}><Trans>Learn more about LogPass</Trans></Button>
          </Grid>
        </Grid>
      </>
    );
}
