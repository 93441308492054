import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Link } from "react-router-dom";
import { Trans } from '@lingui/macro';
import * as API from '../api';

export default function ErrorView(props: any) {
    const state = props.location.state; 
    let error : Error | API.ApiError | string = (state && state.error) || new Error("Invalid url");
    let subErrors : JSX.Element[] = [];
    if(error instanceof API.ApiError) {
      subErrors = error.errors.map((value, index) => (
        <li key={index}>{value.message || value.code}</li>
      ));
    }

    return <Grid container direction="column" justify="space-between" alignItems="center" spacing={2} style={{height: "200px"}}>
          <Grid item>
            <h1 style={{textAlign: 'center', margin: 0}}><Trans>An error occurred</Trans></h1>          
          </Grid>
          <Grid item>
          <h3>{typeof error === "string" ? error : error.message}</h3>
            {subErrors}
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained" fullWidth color="primary" onClick={() => {
              window.history.back();
            }}><Trans>Return to previus page</Trans></Button>
          </Grid>
        </Grid>;
}
